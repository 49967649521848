import React, { useState, useEffect, useMemo } from "react";
import DashboardLayout from "../../../UI/Layout/DashboardLayout";
import UserLeftMenu from "./UserLeftMenu";
import { FiPlus } from "react-icons/fi";
import { BsPencilSquare, BsTrash3 } from "react-icons/bs";
import { Link } from "react-router-dom";
import { HOST_URL_DB2, NODE_HOST_URL } from "../../api/Api";
import dayjs from "dayjs";
import Spining from "../../../UI/Spining/Spining";
import Alert from "../../../UI/Alert/Alert";
import { useParams } from "react-router-dom";
import Select from "react-select";
import Axios from "axios";

const UserMgmt = () => {
  const lStorage = JSON.parse(localStorage.getItem("login"));
  const user_id = lStorage.user.userId;
  const token = lStorage.token;
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    successMessage: false,
    bgColor: "alert-danger",
    errorMessage: "",
  });
  const [agentList, setAgentList] = useState([]);
  const [checkedRows, setCheckedRows] = useState({});
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState([]);
  const [selectedAssign, setSelectedAssign] = useState();
  const [listToTransfer, setListToTransfer] = useState();
  const [transferTo, setTransferTo] = useState();
  const [search, setSearch] = useState("");
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [deleteSt, setDeleteSt ] = useState("");
  const showAgent = async () => {
    setLoading(true);
    const data = {
      user_id,
      method: "retrieve_agent",
      token,
      user_type: "admin",
    };

    try {
      const response = await fetch(`${NODE_HOST_URL}/whatsapp_agent`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      if (result.success) {
        setAgentList(result.data);
        setFilteredAgents(result.data); // Set initial data for filtered agents
      } else {
        console.log("Failed to fetch data:", response.statusText);
      }
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    showAgent();
  }, [user_id]);

  const filterAgent = useMemo(() => {
    // First filter by agent_type (ID), then apply search filter
    return filteredAgents.filter((list) => list.agent_type === id);
  }, [id, filteredAgents]);

  useEffect(() => {
    setCheckedRows({});
    setIsAllChecked(false);
    setSelectedAgent([]);
  }, [id]);

  useEffect(() => {
    const arr = Object.keys(checkedRows)
      .filter((key) => checkedRows[key])
      .map(Number);
    setSelectedAgent(arr);
  }, [checkedRows]);

  useEffect(() => {
    const allChecked = filterAgent.every((list) => checkedRows[list.id]);
    setIsAllChecked(allChecked);
  }, [checkedRows, filterAgent]);

  const handleHeaderCheckboxChange = ({ target: { checked } }) => {
    const updatedCheckedRows = {};
    filterAgent.forEach((list) => {
      updatedCheckedRows[list.id] = checked;
    });
    setCheckedRows(updatedCheckedRows);
    setIsAllChecked(checked);
  };

  const handleRowCheckboxChange = (id) => {
    const updatedCheckedRows = {
      ...checkedRows,
      [id]: !checkedRows[id],
    };
    setCheckedRows(updatedCheckedRows);
    const allChecked = Object.values(updatedCheckedRows).every(Boolean);
    setIsAllChecked(allChecked);
  };

  const handleSelectAssign = (selected) => {
    setSelectedAssign(selected);
    const filterAgent = agentList
      .filter((list) => list.agent_type === selected.value)
      .map((list) => ({
        value: list.id,
        label: list.name,
      }));

    setListToTransfer(filterAgent);
  };

  const handleTransfer = async () => {
    setLoading(true);
    const transfertoAgent = agentList.filter(
      (list) => list.id === transferTo.value
    );
    const datafortransfer = {
      user_id,
      token,
      method: `${id === "agent" ? "agent_transfer" : "manager_transfer"}`,
      transfer_to: transfertoAgent,
      user_type: "admin",
      agent_list: selectedAgent,
    };
    try {
      const { data } = await Axios.post(
        `${NODE_HOST_URL}/whatsapp_agent`,
        datafortransfer
      );
      if (data.success === true) {
        window.location.reload();
        setError({
          successMessage: true,
          bgColor: "alert-success",
          errorMessage: data.message,
        });
      } else {
        setError({
          successMessage: true,
          bgColor: "alert-danger",
          errorMessage: data.message,
        });
      }
    } catch (error) {
      console.log(error.message);
    }
    setLoading(false);
  };

  const handleSearch = async (e) => {
    const query = e.target.value.toLowerCase();
    setSearch(query);

    const filtered = agentList.filter((agent) => {
      return Object.keys(agent).some((key) => {
        return String(agent[key]).toLowerCase().includes(query);
      });
    });

    setFilteredAgents(filtered);
  };
  const deleteAgent = async () => {
    setLoading(true)
    const data = {
      user_id,
      method: "delete",
      token,
      id: deleteSt,
    };
   

    try {
      const response = await fetch(`${HOST_URL_DB2}/agent.php`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      if (result.success) {
       window.location.reload();
        setError({
          successMessage: true,
          bgColor: "alert-success",
          errorMessage: result.message,
        });
      } else {
        console.log("Failed to delete data:", response.statusText);
        setError({
          successMessage: true,
          bgColor: "alert-danger",
          errorMessage: result.message,
        });
      }
    } catch (error) {
      console.error("Error occurred while deleting data:", error);
      setError({
        successMessage: true,
        bgColor: "alert-danger",
        errorMessage: error.message,
      });
    }
    setLoading(false)

  };
  return (
    <>
      <DashboardLayout>
        {/* Modal for transfer */}
        <div
          className="modal fade"
          id="staticBackdrop"
          data-backdrop="static"
          data-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Agent Transfer
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="d-flex flex-column">
                  <div>
                    {id === "agent" && (
                      <Select
                        placeholder="Transfer to"
                        onChange={handleSelectAssign}
                        options={[
                          { value: "manager", label: "Manager" },
                          { value: "team", label: "Team" },
                        ]}
                      />
                    )}
                    {selectedAssign && (
                      <div className="my-4">
                        <Select
                          placeholder={`Select ${selectedAssign.value}`}
                          onChange={(selected) => setTransferTo(selected)}
                          options={listToTransfer}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleTransfer}
                >
                  Transfer
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Modal for delete */}
        <div
          className="modal fade"
          id="staticBackdropdelete"
          data-backdrop="static"
          data-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabeldelete"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabeldelete">
                  Aleart
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="d-flex flex-column">
                  <div>Are you sure want to delete?</div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={deleteAgent}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
        {loading && <Spining />}
        <main className="content">
          {error.successMessage && (
            <Alert type={error.bgColor}>{error.errorMessage}</Alert>
          )}
          <div className="container-fluid p-0">
            <div className="d-flex wFull">
              <div className="ChatLive_card col-md-2">
                <UserLeftMenu />
              </div>
              <div className="col-md-10">
                <div className="ChatLive_card w-100 p-2 d-flex justify-content-between">
                  <div>
                    <input
                      type="text"
                      value={search}
                      onChange={handleSearch}
                      placeholder="Search..."
                    />
                  </div>
                  {selectedAgent.length > 0 && (
                    <button
                      type="button"
                      className="btn btn-outline-success btn-sm"
                      data-toggle="modal"
                      data-target="#staticBackdrop"
                    >
                      {id === "agent" ? "Assign Manager/Team" : "Team"}
                    </button>
                  )}
                  <Link
                    to="/dashboard/add-agent"
                    className="btn btn-outline-success btn-sm"
                  >
                    <FiPlus /> Add Agent
                  </Link>
                </div>
                <div className="ChatLive_card d-flex flex-column wFull px-3 py-3">
                  <div className="row">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          {id !== "team" && (
                            <th>
                              <input
                                className="cursor-pointer"
                                type="checkbox"
                                checked={isAllChecked}
                                onChange={handleHeaderCheckboxChange}
                              />
                            </th>
                          )}
                          <th>Name</th>
                          <th>Email</th>
                          <th>Mobile</th>
                          <th>Manager</th>
                          <th>Team</th>
                          <th>Active Status</th>
                          <th>Available</th>
                          <th>Verified</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filterAgent.map((list) => (
                          <tr key={list.id}>
                            {id !== "team" && (
                              <td>
                                <input
                                  className="cursor-pointer"
                                  type="checkbox"
                                  checked={!!checkedRows[list.id]}
                                  onChange={() =>
                                    handleRowCheckboxChange(list.id)
                                  }
                                />
                              </td>
                            )}
                            <td>{list.name}</td>
                            <td>{list.email}</td>
                            <td>{list.mobile}</td>
                            <td>{list.manager_name}</td>
                            <td>{list.team_name}</td>
                            <td>
                              {list.online === 1 ? (
                                <>
                                  <div
                                    style={{
                                      display: "inline-block",
                                      width: "10px",
                                      height: "10px",
                                      borderRadius: "50%",
                                      backgroundColor: "green",
                                    }}
                                  />
                                  <span style={{ marginLeft: "8px" }}>
                                    Online
                                  </span>
                                </>
                              ) : list.last_seen_datetime === null ? (
                                "NA"
                              ) : (
                                dayjs(list.last_seen_datetime).format(
                                  "DD/MM/YYYY h:mm A"
                                )
                              )}
                            </td>
                            <td>{list.availability === 1 ? "Yes" : "No"}</td>
                            <td>
                              {list.update_first_password === 1 ? "Yes" : "No"}
                            </td>
                            <td>
                              <Link to={`/dashboard/agent/edit/${list.id}`}>
                                <div className="badge bg-primary mr-2 py-2">
                                  <BsPencilSquare size={18} />
                                </div>
                              </Link>
                              <span>
                                <div
                                  className="badge bg-danger py-2"
                                  data-toggle="modal"
                                  data-target="#staticBackdropdelete"
                                  onClick={()=>setDeleteSt(list.id)}
                                >
                                  <BsTrash3 size={18} />
                                </div>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    </>
  );
};

export default UserMgmt;
