import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TbTableShortcut } from "react-icons/tb";
import { LuMousePointer2 } from "react-icons/lu";
import { IoMdSettings } from "react-icons/io";


const ChatLeftMenu = () => {
  const [activeLink, setActiveLink] = useState("");
  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <>
      <div className="py-3">
        <div className="nav flex-column liveChatList">
          <h3>Chat Settings</h3>
          <ul className="sidebar-nav">
          <li>
              <Link
                to="/dashboard/chat-setting"
                className={activeLink === "shortcuts" ? "active" : ""}
                onClick={() => handleLinkClick("shortcuts")}
              >
                <span className="iconWH"></span>
                <IoMdSettings /> Settings
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/chat-setting/shortcuts"
                className={activeLink === "shortcuts" ? "active" : ""}
                onClick={() => handleLinkClick("shortcuts")}
              >
                <span className="iconWH"></span>
                <TbTableShortcut /> Shortcuts
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/chat-setting/trigger"
                className={activeLink === "trigger" ? "active" : ""}
                onClick={() => handleLinkClick("trigger")}
              >
                <span className="iconWH"></span>
                <LuMousePointer2 /> Trigger
              </Link>
            </li>
            {/* <li>
              <Link
                to="/dashboard/chat-setting/notifications"
                className={activeLink === "notifications" ? "active" : ""}
                onClick={() => handleLinkClick("notifications")}
              >
                <span className="iconWH"></span>
                <IoNotificationsOutline /> Mail Notifications
              </Link>
            </li> */}
            {/* <li>
              <Link
                to="/dashboard/webhooks"
                className={activeLink === "webhooks" ? "active" : ""}
                onClick={() => handleLinkClick("webhooks")}
              >
                <span className="iconWH"></span>
                <FaLink /> Webhooks
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ChatLeftMenu;
