import React from "react";
import DashboardLayout from "../../../UI/Layout/DashboardLayout";
import { IoIosStar } from "react-icons/io";
import { BsPencilSquare, BsTrash3 } from "react-icons/bs";
import { Link } from "react-router-dom";

const UserMgmt = () => {
  return (
    <>
      <DashboardLayout>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="d-flex wFull">
              <div className="col-md-12">
                <div className="ChatLive_card d-flex flex-column wFull px-3 py-3">
                  <div className="TitleBox clearfix">
                    <h3>Agents Chat Report</h3>
                  </div>
                  <div className="row">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Total Chat</th>
                          <th>New Chat</th>
                          <th>Repeat Chat</th>
                          <th>Missed chat</th>
                          <th>Average Duration</th>
                          <th>Average Waiting Time</th>
                          <th>Rating</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Nitesh Kumar Singh</td>
                          <td>
                            <Link to="/dashboard/chat-conversation">12</Link>
                          </td>
                          <td>
                            <Link to="/">12</Link>
                          </td>
                          <td>
                            <Link to="/">12</Link>
                          </td>
                          <td>
                            <Link to="/">12</Link>
                          </td>
                          <td>
                            <Link to="/">10 Min</Link>
                          </td>
                          <td>
                            <Link to="/">20 Min</Link>
                          </td>
                          <td>
                            <span className="pinkColor">
                              <IoIosStar />
                              <IoIosStar />
                              <IoIosStar />
                              <IoIosStar />
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    </>
  );
};

export default UserMgmt;
