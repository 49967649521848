import React from "react";
import DashboardLayout from "../../../UI/Layout/DashboardLayout";
import {
  TbMessage2Pin,
  TbMessage2Bolt,
  TbMessage2Plus,
  TbMessage2Check,
  TbMessage2Share,
  TbMessage2X,
} from "react-icons/tb";
import { LiaUserTieSolid } from "react-icons/lia";
import { PiUserGearDuotone } from "react-icons/pi";
import { FiArrowLeft } from "react-icons/fi";

const ChatReport = () => {
  return (
    <div>
      <DashboardLayout>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-md-5">
                <div className="chatBorderBlock px-4 py-4 MinH">
                  <div className="d-flex">
                    <span className="iconCircluate yellowish mr-2">
                      <PiUserGearDuotone />
                    </span>
                    <h6 className="d-flex">Live Visitors</h6>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="row">
                  <div className="col-md-4">
                    <div className="chatBorderBlock px-4 py-4 MinHalf flex-col">
                      <div className="d-flex">
                        <span className="iconCircluate yellowish mr-2">
                          <TbMessage2Pin />
                        </span>
                      </div>
                      <h6 className="d-flex txt-bold">Live Chat</h6>
                      <div className="d-flex">
                        <h4 className="align-self-sm-center">0</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="chatBorderBlock px-4 py-4 MinHalf">
                      <div className="d-flex">
                        <span className="iconCircluate voiletish mr-2">
                          <span class="mdi mdi-chat-processing-outline">
                            <TbMessage2Plus />
                          </span>
                        </span>
                      </div>
                      <h6 className="d-flex">Total Chats</h6>
                      <div className="d-flex">
                        <h4 className="align-self-sm-center">20</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="chatBorderBlock px-4 py-4 MinHalf">
                      <div className="d-flex">
                        <span className="iconCircluate blueish mr-2">
                          <span class="mdi mdi-chat-processing-outline">
                            <TbMessage2Bolt />
                          </span>
                        </span>
                      </div>
                      <h6 className="d-flex">New Chats</h6>
                      <div className="d-flex">
                        <h4 className="align-self-sm-center">20</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-4">
                    <div className="chatBorderBlock px-4 py-4 MinHalf">
                      <div className="d-flex">
                        <span className="iconCircluate orangeish mr-2">
                          <TbMessage2Share />
                        </span>
                      </div>
                      <h6 className="d-flex">Repeated Chats</h6>
                      <div className="d-flex">
                        <h4 className="align-self-sm-center">20</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="chatBorderBlock px-4 py-4 MinHalf">
                      <div className="d-flex">
                        <span className="iconCircluate greenish mr-2">
                          <TbMessage2Check />
                        </span>
                      </div>
                      <h6 className="d-flex">Read Chat</h6>
                      <div className="d-flex">
                        <h4 className="align-self-sm-center">20</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="chatBorderBlock px-4 py-4 MinHalf">
                      <div className="d-flex">
                        <span className="iconCircluate orangeish mr-2">
                          <TbMessage2X />
                        </span>
                      </div>
                      <h6 className="d-flex">Unread Chat</h6>
                      <div className="d-flex">
                        <h4 className="align-self-sm-center">20</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <div className="chatBorderBlock px-4 py-4 MinHx">
                  <div className="d-flex">
                    <span className="iconCircluate greenish mr-2">
                      <LiaUserTieSolid />
                    </span>
                    <h6 className="flex-grow-1">Agent Chat History </h6>
                    <a href="/dashboard/user-management" className="text-sm">
                      View All
                    </a>
                  </div>
                  <table className="table table-striped table-bordered mt-3">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Ap</td>
                        <td>xysz@gmail.com</td>
                        <td>+91-22232323</td>
                        <td>
                          <span class="text-danger">Inactive</span>
                        </td>
                        <td>
                          <a href="/dashboard/agent-report">View More</a>
                        </td>
                      </tr>
                      <tr>
                        <td>Ap</td>
                        <td>xysz@gmail.com</td>
                        <td>+91-22232323</td>
                        <td>
                          <span class="text-success">Active</span>
                        </td>
                        <td>
                          <a href="/dashboard/agent-report">View More</a>
                        </td>
                      </tr>
                      <tr>
                        <td>Ap</td>
                        <td>xysz@gmail.com</td>
                        <td>+91-22232323</td>
                        <td>
                          <span class="text-success">Active</span>
                        </td>
                        <td>
                          <a href="/dashboard/agent-report">View More</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
    </div>
  );
};

export default ChatReport;
