import React from "react";
import ChatLeftMenu from "./ChatLeftMenu";
import DashboardLayout from "../../../UI/Layout/DashboardLayout";

const Settings = () => {
  return <>
  
  <DashboardLayout>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="d-flex wFull">
              <div className="ChatLive_card col-md-2">
                <ChatLeftMenu />
              </div>
              <div className="col-md-10 ">
                <div className="ChatLive_card d-flex flex-column wFull px-3 py-3">
                  <div className="TitleBox clearfix">
                    <h3>
                      Settings{" "}
                      {/* <a
                        href="/dashboard/trigger-edit"
                        class="btn btn-outline-success btn-sm float-right mb-3"
                      >
                        Add Trigger
                      </a> */}
                    </h3>
                  </div>
                  <div className="row">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>
                            <input type="checkbox" />
                          </th>
                          <th>Name</th>
                          <th>Description</th>
                          <th>Type</th>
                          <th>Enabled</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input type="checkbox" />
                          </td>
                          <td>Chat Message</td>
                          <td>Send notification to particular message</td>
                          <td>
                            Sorry to keep you waiting, unfortunately all...
                          </td>
                          
                        </tr>
                        <tr>
                          <td>
                            <input type="checkbox" />
                          </td>
                          <td>Chat Rescue</td>
                          <td>Send notification to particular message..</td>
                          <td>Sorry to keep you waiting, unfortunately...</td>
                         
                         
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DashboardLayout>
  
  </>;
};

export default Settings;
