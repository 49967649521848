import React, { useEffect, useState } from 'react';
import AdminHeader from '../../Header/AdminHeader'
import AdminLeftMenu from '../../Navbar/AdminLeftMenu'
import AdminFooter from '../../Footer/AdminFooter'
import { Link, useParams } from 'react-router-dom';
import { userloginDetails } from '../../Utils/AdminAuth';
import { HOST_URL } from '../../Dashboard/api/Api';
import api from "../../../axios/Axios";
import { ADMIN_USERS_LIST } from '../../../constants/ServiceConstants';
import Alert from '../../UI/Alert/Alert';
import Spining from '../../UI/Spining/Spining';

const UpdateWhatsappToken = () => {
    let { id, brandNumber } = useParams();
    const adminAuthDetails = userloginDetails();

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setisError] = useState(false);
    const [errorType, seterrorType] = useState("");
    const [ErrorResponse, setErrorResponse] = useState(null);
    const [tokenupdate, settokenupdate] = useState("");
    const [apiupdate, setApiupdate] = useState("");
    const [userid,setUserid] = useState('')

    useEffect(() => {
        getWhatsAppDetails();
    }, []);

    const UpdateDetails = async () => {
        try {
            document.querySelector("body").scrollTo(0, 0);
            const data = new FormData();
            data.append("wp_token", tokenupdate);
            data.append("api_key", apiupdate);
            data.append("user_id", adminAuthDetails.user.userId);
            data.append("method", "update_whatsapp_token");
            data.append("token", adminAuthDetails.token);
            data.append("ret_user_id", id);
            data.append("id", userid);
            const response = await fetch(
                `${HOST_URL}/admin_user_list.php`,
                {
                    method: "POST",
                    body: data,
                }
            );

            const result = await response.json(); // Parse JSON here

            if (result.success === true) {
                // Update states or handle response
                setisError(true);
                seterrorType("alert alert-success alert-dismissible");
                setErrorResponse(result.message);

                setTimeout(function () {
                  window.location.reload();
                }, 2000);
            } else {
                setisError(true);
                seterrorType("alert alert-danger alert-dismissible");
                setErrorResponse(result.message);
            }
        } catch (err) {
            console.log(err);
            // Handle error - display an error message or take appropriate action
        }
    };

    const getWhatsAppDetails = async () => {
        try {
            const { data } = await api.post(ADMIN_USERS_LIST, {
                token: adminAuthDetails.token,
                user_id: adminAuthDetails.user.userId,
                ret_user_id: id,
                brand_number: brandNumber,
                method: "ret_whatsapp_profile",
            });
            if (data.success === true) {
                console.log(data.data)
                settokenupdate(data.data[0].token);
                setApiupdate(data.data[0].api_key)
                setUserid(data.data[0].id)
                setIsLoading(false);
            } else {
                setErrorResponse(data.message);
                setIsLoading(false);
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div>
            <div className="wrapper">
                <AdminLeftMenu />
                <div className="main">
                    <AdminHeader />
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row mb-2 mb-xl-3">
                            {isLoading && <Spining />}
                                <div className="col-auto d-none d-sm-block">
                                    <h3><strong className='text-dark'>WhatsApp User List</strong></h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">
                                            <nav className="text-center" aria-label="breadcrumb">
                                                <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                                    <Link to={`/admin/whatsapp-account-profile-details/${id}`}><li style={{fontSize:"13px"}} className="breadcrumb-item">Profile Details</li></Link>
                                                    <Link to={`/admin/whatsapp-account-whatsapp-template-list/${id}`}><li style={{fontSize:"13px"}} className="breadcrumb-item">Whatsapp Template List</li></Link>
                                                    <Link to={`/admin/whatsapp-account-update-whatsapp-token/${id}`}><li style={{fontSize:"13px"}} className="breadcrumb-item active">Update Whastapp Token</li></Link>
                                                    <Link to={`/admin/whatsapp-account-change-route/${id}`}><li style={{fontSize:"13px"}} className="breadcrumb-item">Change Route</li></Link>
                                                </ol>
                                            </nav>
                                        </h5>
                                    </div>
                                    <div className="card-body">
                                    {isError && <Alert type={errorType}> {ErrorResponse} </Alert>}
                                        <div className="form-row ">                                     
                                            <div className="form-group col-md-12">
                                                <label className="col-form-label">Access Token:</label>
                                                <input
                                                    className="form-control"
                                                    placeholder="access Token"
                                                    onChange={(e) => settokenupdate(e.target.value)}
                                                    type="text"
                                                    defaultValue={tokenupdate}
                                                    name="token"
                                                    id="flexCheckDefault"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row ">
                                            <div className="form-group col-md-12">
                                                <label className="col-form-label">API Key:</label>
                                                <input
                                                    className="form-control"
                                                    placeholder="Enter api key"
                                                    onChange={(e) => setApiupdate(e.target.value)}
                                                    type="text"
                                                    defaultValue={apiupdate}
                                                    name="api"
                                                    id="flexCheckDefault"
                                                />
                                                <span style={{ fontSize: "12px", fontWeight: "bold" }}>Note: Api key in mendatory  in case of pinnacel route given to user</span>
                                            </div>
                                        </div>
                                        <button
                                            onClick={UpdateDetails}
                                            className="btn btn-sm float-right btn-primary"
                                        >
                                            Update Token
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <AdminFooter />
                </div>
            </div>
        </div>
    );
};

export default UpdateWhatsappToken;
